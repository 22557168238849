<template>
  <iframe :src="url" width="100%" height="100%" frameborder="0" scrolling="auto"></iframe>
</template>
<script>
export default {
  name: 'MonitoringScreen',
  data() {
    return {
      url: 'https://datav.aliyuncs.com/share/7b2488b8ac6d0807f8eec2a42abb5707?organ_id='+localStorage.getItem('organId')
    }
  },
}
</script>